<script setup lang="ts">
import {useCachedMainNavigation} from "~/components/server/useCachedMainNavigation";

defineOptions({
  name: "DefaultLayout",
});

// Navigation for default theme (depth 1 is 2 level navi, depth 2 is 3 level navi, and so on)
const { data } = useAsyncData("mainNavigation", () => {
  return useCachedMainNavigation (1, "main-navigation");
});
provide("swNavigation-main-navigation", data);

const { data: footerData } = useAsyncData("mainFooterNavigation", () => {
  return useCachedMainNavigation (1, "footer-navigation");
});
provide("swNavigation-footer-navigation", footerData);

const { data: topNavigationData } = useAsyncData( "mainServiceNavigation", ()=> {
  return useCachedMainNavigation (1, "service-navigation");
})
provide("swNavigation-service-navigation", topNavigationData)
</script>
<template>
  <div>
    <LayoutHeader />
    <LayoutNotifications />
    <main class="mx-auto min-h-[700px]">
      <slot />
    </main>
    <LayoutFooter />
  </div>
</template>
